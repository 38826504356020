var getElementUISeries = require('./getElementUISeries')
var varyColor = require('../client/varyColor')
var changeSelector = require('./changeSelector')




module.exports = {
    getElementUISeries: getElementUISeries,
    varyColor: varyColor,
    changeSelector: changeSelector
}
